<template>
  <main class="page-password-change py-5">
    <AwlCard title="Recupera password">
      <ResetPasswordChangeForm class="card-body" />
    </AwlCard>
  </main>
</template>

<script>

export default {
  name: 'page-password-change',
  components: {
    AwlCard: () => import('../../components/Card/AwlModalCard'),
    ResetPasswordChangeForm: () => import('../../components/Account/ResetPasswordChangeForm'),
  },
};

</script>
